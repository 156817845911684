










































































import SfLink from '/storefrontUI/components/components/atoms/SfLink/SfLink.vue';
import SfList from '/storefrontUI/components/components/organisms/SfList/SfList.vue';
import {defineComponent, ref, onMounted, useFetch} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { CategoryTree } from '~/modules/GraphQL/types';
import { useContent, useUiHelpers } from '~/composables';
import type { ComponentTemplateRef } from '~/types/componentTemplateRef';
import SfImage from '/storefrontUI/components/components/atoms/SfImage/SfImage.vue';

export default defineComponent({
  name: 'HeaderNavigationSubcategories',
  components: {
    SfLink,
    SfList,
    SfImage,
  },
  props: {
    currentCategory: {
      type: Object as PropType<CategoryTree | null>,
      default: () => null,
    },
    hasFocus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { getCatLink } = useUiHelpers();
    const lvl1CatRefs = ref<ComponentTemplateRef[]>();
    const lvl2CatRefs = ref<ComponentTemplateRef[]>();
    const lvl2GroupedCatRefs = ref<ComponentTemplateRef[][]>();
    const { loadBlocks } = useContent();

    const banner = [];
    const navBanners = ref();
    const navUomoBanner1 = ref();
    const navUomoBanner2 = ref();
    const navUomoBanner3 = ref();
    const navUomoBanner4 = ref();
    const navDonnaBanner1 = ref();
    const navDonnaBanner2 = ref();
    const navDonnaBanner3 = ref();
    const navDonnaBanner4 = ref();
    const navSaldiBanner1 = ref();
    const navSaldiBanner2 = ref();
    const navSaldiBanner3 = ref();
    const navSaldiBanner4 = ref();
    const navRegaloBanner1 = ref();
    const navRegaloBanner2 = ref();
    const navRegaloBanner3 = ref();
    const navRegaloBanner4 = ref();

    const hasChildren = (category: CategoryTree) => Boolean(category.children ? category.children.length > 0 : 0);

    const getGroupedLvl2CatRefs = () : ComponentTemplateRef[][] => {
      let current = 0;
      const result : ComponentTemplateRef[][] = [];
      lvl1CatRefs.value.forEach((lvl1CatRef) => {
        const groupCount = Number(lvl1CatRef.$el.dataset.children);
        if(lvl2CatRefs.value != undefined ){
          const group = lvl2CatRefs.value.slice(current, current + groupCount);
          result.push(group);
        }
        current += groupCount;
      });

      return result;
    };

    // Once submenu is opened we start from the very first element
    let lvl1CatFocusIdx = 0;
    // We start from the "outside" of lvl2Cats, navigation action must be performed to jump into lvl2CatTree
    let lvl2CatFocusIdx = -1;

    const navRight = () => {
      if (lvl1CatRefs.value[++lvl1CatFocusIdx]) {
        lvl1CatRefs.value[lvl1CatFocusIdx].$el.focus();
        lvl2CatFocusIdx = -1;
        lvl2GroupedCatRefs.value = getGroupedLvl2CatRefs();
      } else {
        lvl1CatFocusIdx--;
      }
    };

    const navLeft = () => {
      if (lvl1CatRefs.value[--lvl1CatFocusIdx]) {
        lvl1CatRefs.value[lvl1CatFocusIdx].$el.focus();
        lvl2CatFocusIdx = -1;
        lvl2GroupedCatRefs.value = getGroupedLvl2CatRefs();
      } else {
        lvl1CatFocusIdx++;
      }
    };

    const navDown = () => {
      lvl2CatFocusIdx++;
      if (lvl2CatFocusIdx !== -1 && !lvl2GroupedCatRefs.value[lvl1CatFocusIdx][lvl2CatFocusIdx]) {
        lvl2CatFocusIdx--;
        return;
      }
      lvl2GroupedCatRefs.value[lvl1CatFocusIdx][lvl2CatFocusIdx].$el.focus();
    };

    const navUp = () => {
      if (lvl2CatFocusIdx > 0) {
        lvl2CatFocusIdx--;
        lvl2GroupedCatRefs.value[lvl1CatFocusIdx][lvl2CatFocusIdx].$el.focus();
        return;
      }

      if (lvl2CatFocusIdx === 0) {
        lvl1CatRefs.value[lvl1CatFocusIdx].$el.focus();
        lvl2CatFocusIdx = -1;

        return;
      }

      if (lvl2CatFocusIdx === -1) {
        emit('hideSubcategories');
      }
    };

    const setupNav = () => {
      lvl2CatFocusIdx = -1;
      lvl1CatRefs.value[lvl1CatFocusIdx].$el.focus();
      lvl2GroupedCatRefs.value = getGroupedLvl2CatRefs();
    };

    useFetch(async () => {
      navBanners.value =  await loadBlocks({identifiers : ['navUomoBanner1', 'navUomoBanner2', 'navUomoBanner3', 'navUomoBanner4', 'navDonnaBanner1', 'navDonnaBanner2', 'navDonnaBanner3', 'navDonnaBanner4', 'navSaldiBanner1', 'navSaldiBanner2', 'navSaldiBanner3', 'navSaldiBanner4', 'navRegaloBanner1', 'navRegaloBanner2', 'navRegaloBanner3', 'navRegaloBanner4']});

      if (Array.isArray(navBanners.value)) {
        navBanners.value.forEach(function (element) {
          let image = ' ';
          let mobile = ' ';
          let href = ' ';
          if(element !== null) {
            image = element.content.match(/<img class="pagebuilder-mobile-hidden" src="(.*?)"+/g);
            mobile = element.content.match(/<img class="pagebuilder-mobile-only" src="(.*?)"+/g);
            href = element.content.match(/<a href="(.*?)"+/g);
          }

          image = Array.isArray(image) ? image[0].replace('<img class="pagebuilder-mobile-hidden" src="', ''): '';
          image = image.replace('"', '');

          mobile = Array.isArray(mobile) ? mobile[0].replace('<img class="pagebuilder-mobile-only" src="', '') : '';
          mobile = mobile.replace('"', '');

          href = Array.isArray(href) ? href[0].replace('<a href="', ''): '';
          href = href.replace('"', '');
          banner.push({href: href, image: image, mobile: mobile});
        });
      }

      navUomoBanner1.value = banner[0];
      navUomoBanner2.value = banner[1];
      navUomoBanner3.value = banner[2];
      navUomoBanner4.value = banner[3];
      navDonnaBanner1.value = banner[4];
      navDonnaBanner2.value = banner[5];
      navDonnaBanner3.value = banner[6];
      navDonnaBanner4.value = banner[7];
      navSaldiBanner1.value = banner[8];
      navSaldiBanner2.value = banner[9];
      navSaldiBanner3.value = banner[10];
      navSaldiBanner4.value = banner[11];
      navRegaloBanner1.value = banner[12];
      navRegaloBanner2.value = banner[13];
      navRegaloBanner3.value = banner[14];
      navRegaloBanner4.value = banner[15];

    });

    onMounted(() => {
      if (props.hasFocus) {
        setupNav();
      }
    });

    return {
      getCatLink,
      hasChildren,
      navRight,
      navLeft,
      navDown,
      navUp,
      setupNav,
      lvl1CatRefs,
      lvl2CatRefs,
      navUomoBanner1,
      navUomoBanner2,
      navUomoBanner3,
      navUomoBanner4,
      navDonnaBanner1,
      navDonnaBanner2,
      navDonnaBanner3,
      navDonnaBanner4,
      navSaldiBanner1,
      navSaldiBanner2,
      navSaldiBanner3,
      navSaldiBanner4,
      navRegaloBanner1,
      navRegaloBanner2,
      navRegaloBanner3,
      navRegaloBanner4,
    };
  },
});
